import ReactGA, { FieldsObject, EventArgs } from 'react-ga';

// Usage: Invoke e.g. "AnalyticsService.shared().trackNavigationBarIconClick()"

class AnalyticsService {

    private static instance: AnalyticsService;

    constructor() {
        if (AnalyticsService.instance) {
            throw new Error('Error - use AnalyticsService.shared()');
        }

        const GOOGLE_ANALYTICS_ID = 'UA-167579651-1';

        ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    }

    // Singleton

    static shared(): AnalyticsService {
        AnalyticsService.instance = AnalyticsService.instance || new AnalyticsService();

        return AnalyticsService.instance;
    }

    // - Tracks

    // Navigation Bar

    trackNavigationBarIconClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Navigation Bar',
            action: 'Icon Click'
        });
    }

    trackNavigationBarAddNewMenuButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Navigation Bar',
            action: 'Add New Menu Button Click'
        });
    }

    // Footer

    trackFooterIconClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Footer',
            action: 'Icon Click'
        });
    }

    // Landing Page

    trackLandingPageTopAddNewMenuButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Landing Page',
            action: 'Add New Menu Button Click',
            label: 'Top'
        });
    }

    trackLandingPageBottomAddNewMenuButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Landing Page',
            action: 'Add New Menu Button Click',
            label: 'Bottom'
        });
    }

    // Contact Page

    trackContactPageEmailAddressLinkClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Contact Page',
            action: 'Email Address Link Click'
        });
    }

    // Pricing Page

    trackPricingPageFreeOptionAddNewMenuButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Pricing Page',
            action: 'Add New Menu Button Click',
            label: 'Free Option'
        });
    }

    trackPricingPageExtendedOptionContactUsButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Pricing Page',
            action: 'Contact Us Button Click',
            label: 'Extended Option'
        });
    }

    // Loader Page

    trackLoaderPageLogoClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Loader Page',
            action: 'Logo Click'
        });
    }

    trackLoaderPagePlaceNameLinkClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Loader Page',
            action: 'Place Name Link Click'
        });
    }

    trackLoaderPageAutomaticRedirect(menuURL: string): void {
        AnalyticsService.shared().trackEvent({
            category: 'Loader Page',
            action: menuURL,
            label: 'Redirect',
            nonInteraction: true
        });
    }

    // Add New Menu Page - Basic

    trackAddNewMenuBasicPageNextButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Add New Menu Page',
            action: 'Next Button Click',
            label: 'Basic'
        });
    }

    trackAddNewMenuBasicPageSendButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Add New Menu Page',
            action: 'Send Button Click',
            label: 'Basic'
        });
    }

    // Add New Menu Page - Details

    trackAddNewMenuDetailsPageNextButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Add New Menu Page',
            action: 'Next Button Click',
            label: 'Details'
        });
    }

    // Add New Menu Page - Summary

    trackAddNewMenuSummaryPageMenuUrlClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Add New Menu Page',
            action: 'Menu Link Click',
            label: 'Summary'
        });
    }

    trackAddNewMenuSummaryPageDownloadImageButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Add New Menu Page',
            action: 'Download Image Button Click',
            label: 'Summary'
        });
    }

    trackAddNewMenuSummaryPageDownloadPDFButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Add New Menu Page',
            action: 'Download PDF Button Click',
            label: 'Summary'
        });
    }

    // Edit Menu Page

    trackEditMenuPageMenuUrlClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Menu Link Click'
        });
    }

    trackEditMenuPageDownloadPDFButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Download PDF Button Click'
        });
    }

    trackEditMenuPageDownloadPNGButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Download PNG Button Click'
        });
    }

    trackEditMenuPageDownloadSVGButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Download SVG Button Click'
        });
    }

    trackEditMenuPageSaveChangesButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Save Changes Button Click'
        });
    }

    // Edit Menu Page - Confirmation Dialog

    trackEditMenuPageConfirmationDialogAppears(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Confirmation Dialog Appears',
            label: 'Confirmation Dialog'
        });
    }

    trackEditMenuPageConfirmationDialogSaveButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Save Button Click',
            label: 'Confirmation Dialog'
        });
    }

    trackEditMenuPageConfirmationDialogCancelButtonClick(): void {
        AnalyticsService.shared().trackEvent({
            category: 'Edit Menu Page',
            action: 'Cancel Button Click',
            label: 'Confirmation Dialog'
        });
    }

    // - Scanner

    trackScannerError(error: any): void {
        AnalyticsService.shared().trackEvent({
            category: 'Scanner',
            action: error,
            label: 'Error',
        });
    }

    // - Track Page Views

    trackPageView(name: string, parameters?: FieldsObject): void {
        if (parameters != null) {
            ReactGA.set(parameters);
        }

        ReactGA.pageview(name);
    }

    // - Track Events

    private trackEvent(eventArguments: EventArgs): void {
        ReactGA.event(eventArguments);
    }

}

export default AnalyticsService;
