import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

export default function Meta(): React.ReactElement {
    return (
        // FormattedMessage tag isn't rendered inside Helmet
        <FormattedMessage id='Meta.title'>
            {(title: string) =>
                <FormattedMessage id='Meta.description'>
                    {(description: string) =>
                        <Helmet>
                            <title>{title}</title>
                            <meta property='og:title' content={title} />
                            <meta property='og:description' content={description} />

                            <meta property='og:locale' content='pl_PL' />
                            <meta property='og:locale:alternate' content='en_US' />

                            <meta property='og:url' content={window.location.href} />
                            <meta property='og:image' content='https://menuly.app/img/logo_for_open_graph.png' />
                            <meta property='og:image:width' content='1200' />
                            <meta property='og:image:height' content='627' />
                            <meta property='og:type' content='website' />

                            <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
                            <meta name='author' content='menuly' />

                            <link rel='canonical' href={window.location.href} />
                        </Helmet>
                    }
                </FormattedMessage>
            }
        </FormattedMessage>

    );
}
