import React from 'react';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl';
import { Localizer } from './utils/i18n';
import { BrowserRouter } from 'react-router-dom';
import { hydrate, render } from 'react-dom';
import Meta from './components/meta';

const localizer = new Localizer();

function Root(): React.ReactElement {
    return (
        <IntlProvider locale={localizer.getCurrentLanguage().code} messages={localizer.getMessages()}>
            <BrowserRouter basename={`${localizer.getCurrentLanguage().code}`}>
                <Meta />
                <App />
            </BrowserRouter>
        </IntlProvider>
    );
}

const rootElement = document.getElementById('root');
if (rootElement != null) {
    if (rootElement.hasChildNodes()) {
        hydrate(<Root />, rootElement);
    } else {
        render(<Root />, rootElement);
    }
} else {
    console.error('No root element found!');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
