import React, { ReactElement, ReactNode } from 'react';
import './header.scss';
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import AnalyticsService from '../../analytics/analytics-service';
import { ReactComponent as LogoHorizontal } from '../../images/logo_horizontal.svg';
import { Navbar, Nav } from 'react-bootstrap';
import { Localizer } from '../../utils/i18n';

interface HeaderProps {
    displayButtons: boolean;
}

interface HeaderState {
    localizer: Localizer;
}

class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props: Readonly<HeaderProps>) {
        super(props);

        this.changeLanguage = this.changeLanguage.bind(this);

        this.state = {
            localizer: new Localizer()
        };
    }

    render(): ReactElement {
        return (
            <Navbar expand='md' variant='light' bg='white' fixed='top' className='p-0 mx-auto'>
                <div className='topbar d-flex w-100 pt-2 px-4'>
                    <Link className='navbar-brand' to='/' onClick={AnalyticsService.shared().trackNavigationBarIconClick}>
                        <LogoHorizontal title='menuly logo' style={{ height: '56px', padding: '5px' }} />
                    </Link>
                    {this.renderToggler()}
                </div>
                {this.renderButtons()}
            </Navbar>
        );
    }

    private renderToggler(): ReactNode {
        if (this.props.displayButtons) {
            return (
                <Navbar.Toggle aria-controls="navbarSupportedContent" className='ml-auto' >
                    <span className='navbar-toggler-icon' />
                </Navbar.Toggle>
            );
        }
    }

    private changeLanguage(): void {
        const currentLanguageCodePath = '/' + this.state.localizer.getCurrentLanguage().code + '/';
        const nextLanguageCodePath = '/' + this.state.localizer.getNextLanguage().code + '/';

        const hrefWithNextLanguage = window.location.href.replace(currentLanguageCodePath, nextLanguageCodePath);

        window.location.href = hrefWithNextLanguage;
    }

    private renderButtons(): ReactNode {
        if (this.props.displayButtons) {
            return (
                <Navbar.Collapse className='px-4 py-2'>
                    <Nav className='ml-auto'>
                        <div className='container flex-column flex-md-row'>
                            <button onClick={this.changeLanguage} className='btn btn-outline-none'>
                                {this.state.localizer.getNextLanguage().flag}
                            </button>
                            <Link to='/scanner' className='nav-link active d-lg-none pt-2'>
                                <FormattedMessage id="Navigation.Scanner.Button.title" />
                            </Link>
                            <Link to='/contact' className='nav-link active pt-4 pt-md-2'>
                                <FormattedMessage id="Navigation.Contact.Button.title" />
                            </Link>
                            <Link to='/pricing' className='nav-link active pt-4 pt-md-2'>
                                <FormattedMessage id="Navigation.Pricing.Button.title" />
                            </Link>
                            <Link to='/new'
                                className='btn btn-primary text-white ml-2 my-4 my-md-0'
                                onClick={AnalyticsService.shared().trackNavigationBarAddNewMenuButtonClick}>
                                <FormattedMessage id="Navigation.AddMenu.Button.title" />
                        </Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            );
        }
    }
}

export default Header;
