import React, {ReactElement} from 'react';
import AnalyticsService from '../../analytics/analytics-service';
import {Link} from 'react-router-dom';
import { ReactComponent as LogoVertical } from '../../images/logo_vertical.svg';

function Footer(): ReactElement {
    return (
        <footer className='d-flex py-2 text-center'>
            <div className='mx-auto'>
                <Link to='/' onClick={AnalyticsService.shared().trackFooterIconClick}>
                    <LogoVertical title='menuly logo' style={{width: '90px'}} />
                </Link>
                <small className='d-block mb-3 text-muted p-2'>&copy; 2020</small>
            </div>
        </footer>
    );
}

export default Footer;
