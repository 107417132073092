import React, { ReactElement } from 'react';

import DefaultLayout from '../../layout/default';
import LoadingContent from '../../components/static-page/loading-content';

function Loading(): ReactElement {
    return (
        <DefaultLayout displayHeaderButtons={true}>
            <LoadingContent />
        </DefaultLayout>
    );
}

export default Loading;
