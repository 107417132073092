import React, {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import CookieConsent from 'react-cookie-consent';

interface DefaultLayoutProps {
    displayHeaderButtons: boolean;
}

class DefaultLayout extends React.Component<DefaultLayoutProps, {}> {

    render(): ReactElement {
        return (
            <div>
                <Header displayButtons={this.props.displayHeaderButtons}/>
                <div style={{minHeight: 'calc(100vh - 268px)'}}>
                    {this.props.children}
                </div>
                <Footer/>
                <CookieConsent buttonText={<FormattedMessage id="Common.CookieConsent.Button.title" />}>
                    <FormattedMessage id= "Common.CookieConsent.mainText" />
                </CookieConsent>
            </div>
        );
    }
}

export default DefaultLayout;
