import React, { ReactElement, Suspense } from 'react';
import { Route, RouteComponentProps, Redirect, Switch, withRouter } from 'react-router-dom';
import AnalyticsService from './analytics/analytics-service';

import ScrollToTop from './utils/ScrollToTop';
import Loading from './pages/loading/loading';

const Contact = React.lazy(() => import('./pages/contact/contact'));
const Edit = React.lazy(() => import('./pages/edit/edit'));
const Error404 = React.lazy(() => import('./pages/error/error-404'));
const Landing = React.lazy(() => import('./pages/landing/landing'));
const Loader = React.lazy(() => import('./pages/loader/loader'));
const New = React.lazy(() => import('./pages/new/new'));
const Pricing = React.lazy(() => import('./pages/pricing/pricing'));
const Scanner = React.lazy(() => import('./pages/scanner/scanner'));

class App extends React.Component<RouteComponentProps, {}> {

    private unlisten?: () => void;

    constructor(props: Readonly<RouteComponentProps>) {
        super(props);
        this.unlisten = this.props.history.listen((location) =>
            AnalyticsService.shared().trackPageView(location.pathname, { page: location.pathname })
        );
    }

    componentDidMount(): void {
        AnalyticsService.shared().trackPageView(window.location.pathname);
    }

    componentWillUnmount(): void {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render(): ReactElement {
        return (
            <Suspense fallback={<Loading />}>
                <ScrollToTop />
                <Switch>
                    <Route exact path='/'>
                        <Redirect to='/home' />
                    </Route>
                    <Route exact path='/home' component={Landing} />
                    <Route exact path='/contact' component={Contact} />
                    <Route exact path='/new' component={New} />
                    <Route exact path='/pricing' component={Pricing} />
                    <Route exact path='/scanner' component={Scanner} />
                    <Route exact path="/:path" render={props => <Loader path={props.match.params.path} />} />
                    <Route exact path='/:path/edit' render={props => <Edit path={props.match.params.path} />} />
                    <Route component={Error404} />
                </Switch>
            </Suspense>
        );
    }

}

export default withRouter(App);
