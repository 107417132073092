import localeData from '../locales/localizations.json';

export class Localizer {

    getCurrentLanguage(): Language {
        const languageDeterminer = new LanguageDeterminer();

        return languageDeterminer.determineUserLanguage(navigator.languages || [], window.location.pathname);
    }

    getMessages(): { [index: string]: string } {
        const messagesAllLanguages: { [index: string]: { [index: string]: string } } = localeData;
        const languageWithoutRegionCode = this.getCurrentLanguage().code.toLowerCase().split(/[_-]+/)[0];

        return messagesAllLanguages[languageWithoutRegionCode] || messagesAllLanguages[this.getCurrentLanguage().code] || localeData.en;
    }

    getNextLanguage(): Language {
        const languageChanger = new LanguageChanger();

        const nextLanguage = languageChanger.getNextLanguage(this.getCurrentLanguage());

        return nextLanguage;
    }
}

interface Language {
    readonly code: string;
    readonly description: string;
    readonly flag: string;
}

class LanguagePolish implements Language {
    code = 'pl';
    description = 'Polish (polski)';
    flag= '🇵🇱';
}

class LanguageEnglish implements Language {
    code = 'en';
    description = 'English';
    flag= '🇬🇧';
}

class SupportedLanguages {

    allSupportedLanguages: Language[];

    constructor(allSupportedLanguages: Language[] = [new LanguagePolish, new LanguageEnglish]) {
        this.allSupportedLanguages = allSupportedLanguages;
    }

    defaultLanguage(): Language {
        return this.allSupportedLanguages[0];
    }

    getSupportedLanguageBasedOnLanguageCode(languageCode: string): Language | undefined {
        return this.allSupportedLanguages.find(e => e.code === languageCode);
    }
}

class LanguageChanger {

    languages: Language[];

    constructor(languages: Language[] = new SupportedLanguages().allSupportedLanguages) {
        this.languages = languages;
    }

    getNextLanguage(currentLanguage: Language): Language {
        const index = this.languages.findIndex(e => e.code === currentLanguage.code);

        let nextIndex = index + 1;

        if (nextIndex >= this.languages.length) {
            nextIndex = 0;
        }

        return this.languages[nextIndex];
    }
}

class LanguageDeterminer {

    determineUserLanguage(acceptedLanguageCodes: readonly string[], path?: string): Language {
        if (path) {
            const urlLang = path.trim().split('/')[1];
            const matchingUrlLang = this.findFirstSupportedLanguage([this.stripCountry(urlLang)]);

            if (matchingUrlLang) {
                return matchingUrlLang;
            }
        }

        const matchingAcceptedLang = this.findFirstSupportedLanguage(acceptedLanguageCodes.map(this.stripCountry));

        return matchingAcceptedLang || new SupportedLanguages().defaultLanguage();
    }

    private findFirstSupportedLanguage(languageCodes: string[]): Language | undefined {
        const allSupportedLanguageCodes = new SupportedLanguages().allSupportedLanguages.map(language => language.code);

        const foundSupportedLanguageCode = languageCodes.find(code => allSupportedLanguageCodes.includes(code));

        let result: Language | undefined;

        if (foundSupportedLanguageCode) {
            result = new SupportedLanguages().getSupportedLanguageBasedOnLanguageCode(foundSupportedLanguageCode);
        }

        return result
    }

    private stripCountry(lang: string): string {
        return lang
                .trim()
                .replace('_', '-')
                .split('-')[0];
    }
}
